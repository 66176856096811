body {
  flex-direction: column;
  height: 100vh;
  background-color: #f1f3f4;
  margin: 0;
  padding: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 600px) {
  body {
    height: auto
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  width: 100%;
  background-color: #f1f3f4;
  justify-content: center;
}
